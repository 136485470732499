import { Flex, Stack } from '@mantine/core';
import { Navbar } from 'features/navbar';
import { split } from 'ramda';
import { FC, ReactNode, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';

export const PageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const [cookies, setCookie] = useCookies(['authorized']);
  const isAuthorized = !!cookies.authorized; // Проверка наличия куки

  return (
    <Stack style={{ position: 'relative' }}>
      <Flex maw={'100vw'} style={{ overflow: 'hidden' }}>
        {isAuthorized && <Navbar />}
        <Stack ml={185} w={'100%'} p={{ base: 16, md: 20 }}>
          {children}
        </Stack>
      </Flex>
    </Stack>
  );
};
