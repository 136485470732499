import { Center, Flex, NumberFormatter, Stack, Title } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$getSumBalances } from 'entities/statistic';
import { or } from 'ramda';
import { useEffect } from 'react';
import { DollarSign } from 'react-feather';
import { Logo } from 'shared/model/icons';

export const SumBalances = () => {
  const [sumBalances] = useUnit([
    $$getSumBalances.$store.map((s) => s.res?.data),
  ]);

  useEffect(() => {
    $$getSumBalances.argEditEvent();
  }, []);

  return (
    <Flex w='100%' gap='20px'>
      <Flex
        w={'100%'}
        gap={16}
        fz={20}
        align={'center'}
        bg={'white'}
        className='shadow'
        p={16}
        style={{ borderRadius: '10px', border: '1px solid #e0e0e0' }}>
        <Logo height={50} width={50} />

        <Stack gap={0}>
          <Title fw={400} fz={16} c={'gray'} order={4}>
            Баланс Чайника
          </Title>
          <div style={{ fontSize: '20px' }}>
            <NumberFormatter
              thousandSeparator=' '
              decimalSeparator=','
              suffix=' ₽'
              value={or(sumBalances?.chainikBalance, 0)}
            />
          </div>
        </Stack>
      </Flex>

      <Flex
        className='shadow'
        w={'100%'}
        gap={16}
        fz={20}
        bg={'white'}
        p={16}
        style={{ borderRadius: '10px', border: '1px solid #e0e0e0' }}>
        <Title>B2P</Title>

        <Stack gap={0}>
          <Title fw={400} fz={16} c={'gray'} order={4}>
            Баланс B2P
          </Title>
          <div style={{ fontSize: '20px' }}>
            <NumberFormatter
              thousandSeparator=' '
              decimalSeparator=','
              suffix=' ₽'
              value={or(sumBalances?.pspBalance, 0)}
            />
          </div>
        </Stack>
      </Flex>

      <Flex
        className='shadow'
        gap={16}
        fz={20}
        bg={'white'}
        w={'100%'}
        p={16}
        style={{ borderRadius: '10px', border: '1px solid #e0e0e0' }}>
        <DollarSign height={50} width={50} color='green' />
        <Stack gap={0}>
          <Title fw={400} fz={16} c={'gray'} order={4}>
            Разница
          </Title>
          <NumberFormatter
            thousandSeparator=' '
            decimalSeparator=','
            suffix=' ₽'
            value={or(
              sumBalances &&
                sumBalances?.chainikBalance - sumBalances?.pspBalance,
              0,
            )}
          />
        </Stack>
      </Flex>
    </Flex>
  );
};
