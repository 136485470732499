import { createTheme, MantineProvider, rem } from '@mantine/core';

export const theme = createTheme({
  colors: {
    // Add your color
    brand: [
      'rgba(111,128,174,0.5)',
      'rgba(207, 216, 255, 0.25)',
      '#9dadff',
      '#667ffd',
      '#3958fb',
      '#1d40fa',
      '#3756FB',
      '#0026e1',
      '#0022ca',
      '#001CB2',
      'rgba(55, 86, 251, 0.3)',
    ],
  },
});
