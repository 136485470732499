import { Box, Flex, NumberFormatter, Stack, Text, Title } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$getPaymentMetrics } from 'entities/statistic';
import '@mantine/charts/styles.css';
import { BarChart, LineChart } from '@mantine/charts';

import { useEffect } from 'react';

import { CardMetrics } from 'features/statistic';
import { ResponsivePie } from '@nivo/pie';
import PieStat from './pie-stat';
import { ResponsiveMarimekko } from '@nivo/marimekko';
import { ResponsiveBar } from '@nivo/bar';

export const Metrics = () => {
  const [metrics] = useUnit([
    $$getPaymentMetrics.$store.map((s) => s.res?.data),
  ]);

  useEffect(() => {
    $$getPaymentMetrics.argEditEvent();
  }, []);

  const data = [
    {
      date: 'Платежи СБП',
      Оплата: metrics?.percentPayInSBP || 0,
      Вывод: 0, // Нет данных для вывода
    },
    {
      date: 'Платежи Карта',
      Оплата: metrics?.percentPayInCard || 0,
      Вывод: 0, // Нет данных для вывода
    },
    {
      date: 'Вывод СБП',
      Оплата: 0, // Нет данных для оплаты
      Вывод: metrics?.percentPayOutSBP || 0,
    },

    {
      date: 'Вывод Карта',
      Оплата: 0, // Нет данных для оплаты
      Вывод: metrics?.percentPayOutCard || 0,
    },
  ];

  return (
    <Stack w='100%' gap='xl' align='center'>
      <Title order={3} mt='lg'>
        Метрики
      </Title>
      <Flex gap={20} mt={-20} w={'100%'}>
        <PieStat
          title={
            <Stack>
              <Text fz={20} lh={0.75}>
                Платежи
              </Text>
              <Stack
                className='shadow'
                bg={'white'}
                p={8}
                style={{ borderRadius: '10px', border: '1px solid #e0e0e0' }}
                gap={0}>
                <Flex justify={'space-around'}>
                  <Stack gap={0}>
                    <Text fz={16} fw={500}>
                      Неудачные
                    </Text>
                    <Text fz={16} fw={500}>
                      {metrics?.totalMetrics?.payIn?.unsuccessfulPayments}
                    </Text>
                  </Stack>
                  <Stack gap={0}>
                    <Text fz={16} fw={500}>
                      Успешные
                    </Text>
                    <Text fz={16} fw={500}>
                      {metrics?.totalMetrics?.payIn?.successfulPayments}
                    </Text>
                  </Stack>
                </Flex>
              </Stack>
            </Stack>
          }
          metrics={[
            {
              id: 'СБП успешные',
              label: 'СБП +',
              value: metrics?.SBPMetrics?.payIn?.successfulPayments,
              color: '#4dabf7 ',
            },
            {
              id: 'Карты успешные',
              label: 'Карты +',
              value: metrics?.cardMetrics?.payIn?.successfulPayments,
              color: '#4dabf7 ',
            },
            {
              id: 'СБП неудачные',
              label: 'СБП -',
              value: metrics?.SBPMetrics?.payIn?.unsuccessfulPayments,
              color: '#ff6b6b',
            },
            {
              id: 'Карты неудачные',
              label: 'Карты -',
              value: metrics?.cardMetrics?.payIn?.unsuccessfulPayments,
              color: '#ff6b6b',
            },
          ]}
        />
        <PieStat
          title={
            <Stack>
              <Text fz={20} lh={0.75}>
                Вывод
              </Text>
              <Stack
                className='shadow'
                bg={'white'}
                p={8}
                style={{ borderRadius: '10px', border: '1px solid #e0e0e0' }}
                gap={0}>
                <Flex justify={'space-around'}>
                  <Stack gap={0}>
                    <Text fz={16} fw={500}>
                      Неудачные
                    </Text>
                    <Text fz={16} fw={500}>
                      {metrics?.totalMetrics?.payOut?.unsuccessfulPayments}
                    </Text>
                  </Stack>
                  <Stack gap={0}>
                    <Text fz={16} fw={500}>
                      Успешные
                    </Text>
                    <Text fz={16} fw={500}>
                      {metrics?.totalMetrics?.payOut?.successfulPayments}
                    </Text>
                  </Stack>
                </Flex>
              </Stack>
            </Stack>
          }
          metrics={[
            {
              id: 'СБП успешные',
              label: 'СБП +',
              value: metrics?.SBPMetrics?.payOut?.successfulPayments,
              color: '#4dabf7 ',
            },
            {
              id: 'Карты успешные',
              label: 'Карты +',
              value: metrics?.cardMetrics?.payOut?.successfulPayments,
              color: '#4dabf7 ',
            },
            {
              id: 'СБП неудачные',
              label: 'СБП -',
              value: metrics?.SBPMetrics?.payOut?.unsuccessfulPayments,
              color: '#ff6b6b',
            },
            {
              id: 'Карты неудачные',
              label: 'Карты -',
              value: metrics?.cardMetrics?.payOut?.unsuccessfulPayments,
              color: '#ff6b6b',
            },
          ]}
        />
      </Flex>
      <Flex gap={16} mt={80} w={'100%'}>
        <Stack
          w={'100%'}
          bg={'white'}
          gap={0}
          p={16}
          className='shadow'
          style={{ borderRadius: '10px', border: '1px solid #e0e0e0' }}>
          <Stack gap={0}>
            <Flex gap={3} justify='space-between'>
              <Text>Всего:</Text>
              <Text fw={500}>
                {metrics?.totalMetrics?.payIn?.numberOfPayments}
              </Text>
            </Flex>
            <Flex gap={3} justify='space-between'>
              <Text>Сумма:</Text>
              <Text fw={500}>
                <NumberFormatter
                  thousandSeparator=' '
                  decimalSeparator=','
                  suffix=' ₽'
                  value={metrics?.totalMetrics?.payIn?.paymentAmount}
                />
              </Text>
            </Flex>
          </Stack>
          <Stack mt={8}>
            <Flex gap={3} c={'brand'} align={'center'} justify='space-between'>
              <Text fw={500} fz={20}>
                Наш доход:
              </Text>
              <Text fw={700} fz={20}>
                <NumberFormatter
                  thousandSeparator=' '
                  decimalSeparator=','
                  suffix=' ₽'
                  value={metrics?.totalMetrics?.payIn?.revenue}
                />
              </Text>
            </Flex>
          </Stack>
        </Stack>
        <Stack
          w={'100%'}
          bg={'white'}
          gap={0}
          className='shadow'
          p={16}
          style={{ borderRadius: '10px', border: '1px solid #e0e0e0' }}>
          <Stack gap={0}>
            <Flex gap={3} justify='space-between'>
              <Text>Всего:</Text>
              <Text fw={500}>
                {metrics?.totalMetrics?.payOut?.numberOfPayments}
              </Text>
            </Flex>
            <Flex gap={3} justify='space-between'>
              <Text>Сумма:</Text>
              <Text fw={500}>
                <NumberFormatter
                  thousandSeparator=' '
                  decimalSeparator=','
                  suffix=' ₽'
                  value={metrics?.totalMetrics?.payOut?.paymentAmount}
                />
              </Text>
            </Flex>
          </Stack>
          <Stack mt={8}>
            <Flex gap={3} c={'brand'} align={'center'} justify='space-between'>
              <Text fw={500} fz={20}>
                Наш доход:
              </Text>
              <Text fw={700} fz={20}>
                <NumberFormatter
                  thousandSeparator=' '
                  decimalSeparator=','
                  suffix=' ₽'
                  value={metrics?.totalMetrics?.payOut?.revenue}
                />
              </Text>
            </Flex>
          </Stack>
        </Stack>
      </Flex>
      <Flex w='100%' gap={'20px'}>
        <Stack w={'60%'} align='center'>
          <Text fz={20}>Платежи</Text>
          <CardMetrics title='По карте' item={metrics?.cardMetrics?.payIn} />
          <CardMetrics title='По СБП' item={metrics?.SBPMetrics?.payIn} />
        </Stack>

        <Stack w={'60%'} align='center'>
          <Text fz={20}>Вывод</Text>
          <CardMetrics title='По карте' item={metrics?.cardMetrics?.payOut} />
          <CardMetrics title='По СБП' item={metrics?.SBPMetrics?.payOut} />
        </Stack>
      </Flex>

      <Title order={3} mt='lg'>
        Проценты Платежей
      </Title>

      <div style={{ height: '400px', width: '100%' }}>
        <ResponsiveBar
          data={data}
          keys={['Оплата', 'Вывод']} // Ключи для отображения
          indexBy='date' // Ось X
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={['#4dabf7', '#ff6b6b']} // Цвета для "Оплата" и "Вывод"
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Тип платежа',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Процент',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          // motionStiffness={90}
          // motionDamping={15}
        />
      </div>
    </Stack>
  );
};
