import { Center, Flex, Image, Stack, Title, Text } from '@mantine/core';
import { useForm } from 'effector-forms';
import { $$adminSignIn, $adminSignIn, $adminSignInForm } from 'entities/admin';
import { FormEvent, useState } from 'react';
import { useUnit } from 'effector-react';
import { SignInForm } from 'features/admin';
import { SendPin } from 'features/admin';
import { Condition } from 'shared/ui';
import { adminSignInFx, showErrorFx } from 'entities/admin/effects';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router';
import '@mantine/notifications/styles.css';
import { notifications } from '@mantine/notifications';
import { Logo } from 'shared/model/icons';
type PropsType = {
  onSuccess?: () => void;
};

export function LoginPage({ onSuccess }: PropsType) {
  const {
    fields,
    submit,
    isDirty,
    reset,
    values: formValues,
    eachValid,
  } = useForm($adminSignInForm);

  const [isLogin, setIsLogin] = useState(false);

  const {
    0: data,
    1: successfulLogin,
    // 2: isLoadingSignIn,
  } = useUnit([
    $adminSignIn,
    // adminSignInFx.fail.,
    $$adminSignIn.attachedFx.pending,
  ]);

  const [cookies] = useCookies(['authorized']);

  if (cookies.authorized) {
    return <Navigate to='/my-profile' replace />;
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();

    adminSignInFx({
      email: formValues.email,
      password: formValues.password,
    })
      .then((res) => {
        setIsLogin(true);
      })
      .catch((error) => {
        notifications.show({
          autoClose: 2000,
          title: 'Ошибка входа',
          message: 'Произошла ошибка при входе. Попробуйте снова.',
          color: 'red',
        });
        reset();
        setIsLogin(false);
      });
  };

  return (
    <Center>
      <Stack
        bg={'white'}
        p={16}
        ml={-185}
        style={{ borderRadius: '10px' }}
        gap={20}
        mt={60}
        w={600}>
        <Title ta='center' order={3}>
          Вход
        </Title>

        <Condition when={!isLogin}>
          <SignInForm handleSubmit={handleSubmit} />
        </Condition>

        <Condition
          when={Boolean(isLogin && data?.qrCode && data?.qrCode?.length > 0)}>
          <Flex justify='center'>
            <Stack style={{ borderRadius: '24px' }} p={24} bg='blue' w={338}>
              <Image
                radius={24}
                src={`data:image/png;base64,${data?.qrCode}`}
              />
            </Stack>
          </Flex>
        </Condition>
        <Condition when={Boolean(isLogin)}>
          <SendPin />
        </Condition>
      </Stack>
    </Center>
  );
}
