import { Center, Stack, Text, Title } from '@mantine/core';

import '@mantine/charts/styles.css';

import { Metrics, SumBalances } from 'widgets/statistic';

export const Statistic = () => {
  return (
    <Stack>
      <Title order={2}>Статистика</Title>

      <SumBalances />
      <Metrics />
    </Stack>
  );
};
