import React, { useEffect, useState } from 'react';
import { Flex, NavLink, Stack, Title } from '@mantine/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOutFx } from 'entities/admin/effects';
import { useCookies } from 'react-cookie';
import { $$adminGetOneOwn } from 'entities/admin';
import { useUnit } from 'effector-react';
import styles from './styles.module.css';
import { Logo } from 'shared/model/icons';
import {
  Home,
  Lock,
  LogOut,
  PieChart,
  User,
  UserPlus,
  Users,
} from 'react-feather';
const data = [
  // { link: '/create-moder', label: 'Добавить модератора' },
  {
    link: '/my-profile',
    label: 'Профиль',
    icon: <User color='#3756FB' height={20} />,
  },
  {
    link: '/organizations',
    label: 'Организации',
    icon: <Home color='#3756FB' height={20} />,
  },
  {
    link: '/statistic',
    label: 'Статистика',
    icon: <PieChart color='#3756FB' height={20} />,
  },
  {
    link: '/users',
    label: 'Юзеры',
    icon: <Users color='#3756FB' height={20} />,
  },
  { link: '/fraud', label: 'Фрод', icon: <Lock color='#3756FB' height={20} /> },
];

export function Navbar() {
  const [, , removeCookie] = useCookies(['authorized']);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleLogout = () => {
    signOutFx();
    removeCookie('authorized', { path: '/' });
    navigate('/login');
  };
  const currentClassname = (link: string, pathname: string) => {
    const isActive = link === pathname ? styles.active : '';
    return styles.item + ' ' + isActive;
  };
  const { 0: dataAdmin } = useUnit([
    $$adminGetOneOwn.$store.map((s) => s.res?.data),
  ]);

  return (
    <Stack
      h={'100vh'}
      pos={'fixed'}
      style={{ zIndex: 1000 }}
      bg='white'
      align='center'
      p='0'
      justify='space-between'>
      <Stack justify='space-between' py={16}>
        <Flex justify={'center'} align={'center'} gap={8} px={8}>
          <Logo height={35} width={35} />
          <Title>Чайник</Title>
        </Flex>
        {data.map((item) => (
          <Stack key={item.link}>
            <NavLink
              className={currentClassname(item.link, currentPath)}
              color='black'
              variant='subtle'
              active
              leftSection={item.icon}
              component={Link}
              label={item.label}
              to={item.link}
            />
          </Stack>
        ))}
        {dataAdmin?.role === 'admin' && (
          <NavLink
            color='black'
            variant='subtle'
            className={currentClassname('/create-moder', currentPath)}
            active
            leftSection={<UserPlus color='#3756FB' height={20} />}
            component={Link}
            label='Добавить модера'
            to='/create-moder'
          />
        )}
        <Stack>
          <NavLink
            color='brand'
            variant='subtle'
            active
            leftSection={<LogOut height={20} />}
            fw={500}
            label='Выйти '
            onClick={handleLogout}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
