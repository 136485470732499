import {
  Flex,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  TextInput,
  Table,
  MultiSelect,
  Badge,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { OrgEntityType, StatusValueType } from 'entities/entities-types';
import { $$getManyOrg } from 'entities/org';
import { useEffect, useState, useMemo } from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { orgStatusColor, orgStatusTranslate } from 'shared/lib';

export const Organizations = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [selectedStatuses, setSelectedStatuses] = useState<StatusValueType[]>(
    [],
  );

  // Получаем поисковый запрос из параметров URL
  const searchQuery = searchParams.get('search') || '';

  const { 0: orgs, 1: isLoading } = useUnit([
    $$getManyOrg.$store.map((s) => s.res?.data),
    $$getManyOrg.attachedFx.pending,
  ]);

  useEffect(() => {
    $$getManyOrg.argEditEvent({
      currentPage: 1,
      itemsPerPage: 10000,
    });
  }, []);

  const orgStatus: StatusValueType[] = [
    'received',
    'considering',
    'canceled',
    'denied',
    'approved',
    'removed',
    'suspended',
    'blocked',
  ];

  // Фильтрация организаций по имени и статусам
  const filteredOrgs = useMemo(() => {
    return (orgs || []).filter((org) => {
      const matchesSearchQuery = org.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesStatus =
        selectedStatuses.length > 0
          ? selectedStatuses.includes(org.status)
          : true;
      return matchesSearchQuery && matchesStatus;
    });
  }, [orgs, searchQuery, selectedStatuses]);

  // Сортировка организаций по дате создания
  const sortedOrgs = useMemo(() => {
    return filteredOrgs.sort((a, b) => {
      const dateA = new Date(a.createdAt as Date).getTime();
      const dateB = new Date(b.createdAt as Date).getTime();
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });
  }, [filteredOrgs, sortOrder]);

  // Строки таблицы
  const rows = sortedOrgs.map((org, index) => (
    <Table.Tr
      bg={index % 2 === 0 ? 'white' : 'brand.1'}
      key={org.orgId}
      onClick={() => navigate(`/profile-org/${org.orgId}`)}
      style={{ cursor: 'pointer' }}>
      <Table.Td>{org.name}</Table.Td>
      <Table.Td>{org.email || '-'}</Table.Td>
      <Table.Td>{org.phoneNumber || '-'}</Table.Td>
      <Table.Td>
        <Badge color={orgStatusColor(org.status)}>
          {orgStatusTranslate(org.status)}
        </Badge>
      </Table.Td>
      <Table.Td>
        {new Date(org.createdAt as Date).toLocaleDateString()}
      </Table.Td>
    </Table.Tr>
  ));

  // Обработчик изменения поискового запроса
  const handleSearchChange = (value: string) => {
    setSearchParams({ search: value });
  };

  return (
    <Stack>
      <Stack>
        <LoadingOverlay h='100vh' visible={isLoading} />
        <Title order={3}>Все организации</Title>
        <Text>Всего организаций - {orgs?.length}</Text>

        {/* Поиск и фильтрация */}
        <Flex gap='md'>
          <TextInput
            value={searchQuery}
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder='Поиск по названию'
            mb={20}
          />
          <MultiSelect
            placeholder='Фильтр по статусу'
            value={selectedStatuses}
            onChange={(values) =>
              setSelectedStatuses(values as StatusValueType[])
            }
            data={orgStatus.map((status) => ({
              value: status,
              label: orgStatusTranslate(status),
            }))}
            renderOption={(item) => (
              <Text c={orgStatusColor(item.option.value)}>
                {orgStatusTranslate(item.option.value)}
              </Text>
            )}
            clearable
          />
        </Flex>

        {/* Таблица */}
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Название</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Телефон</Table.Th>
              <Table.Th>Статус</Table.Th>
              <Table.Th
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')
                }>
                <Flex align='center'>
                  Дата создания{' '}
                  {sortOrder === 'asc' ? (
                    <ArrowDown height={16} />
                  ) : (
                    <ArrowUp height={16} />
                  )}
                </Flex>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Stack>
    </Stack>
  );
};
