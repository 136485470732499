import {
  Button,
  Flex,
  Image,
  PasswordInput,
  PinInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { $$adminSignIn, $$adminSignIn2FA } from 'entities/admin';
import { adminSignIn2FaFx } from 'entities/admin/effects';

import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router';

export function SendPin() {
  const [pinCode, setPinCode] = useState('');
  const [isPinCodeComplete, setIsPinCodeComplete] = useState(false);
  const [cookies, setCookie] = useCookies(['authorized']);
  const handlePinInputChange = (value: string) => {
    setPinCode(value);
    setIsPinCodeComplete(value.length === 6);
  };
  const navigate = useNavigate();

  const sendCode = () => {
    adminSignIn2FaFx({ code: pinCode })
      .then(() => {
        // Успешная авторизация
        setCookie('authorized', 'авторизован');
        navigate('/my-profile');
      })
      .catch(() => {
        notifications.show({
          autoClose: 2000,
          title: 'Ошибка входа',
          message: 'Неверный пин',
          color: 'red',
        });
        setPinCode('');
      });
  };

  return (
    <Flex justify='center'>
      <Stack gap={20}>
        <Text fz={17}>Введите ваш код</Text>

        <PinInput
          size='lg'
          length={6}
          placeholder=''
          type='number'
          onKeyDown={(e) => e.key === 'Enter' && sendCode()}
          value={pinCode}
          onChange={handlePinInputChange}
        />

        <Button
          size='lg'
          radius={10}
          disabled={!isPinCodeComplete}
          bg={!isPinCodeComplete ? 'lightgray' : 'brand'}
          onClick={() => sendCode()}>
          Отправить
        </Button>
      </Stack>
    </Flex>
  );
}
