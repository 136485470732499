import {
  Button,
  Flex,
  LoadingOverlay,
  Menu,
  Stack,
  Text,
  Title,
  TextInput,
  MultiSelect,
  Table,
  Badge,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import {
  $getManyUsers,
  changeStatusActivateUserFx,
  changeStatusBlockUserFx,
  changeStatusSuspendUserFx,
  getManyUsersFx,
} from 'entities/users';
import { useEffect, useState, useMemo } from 'react';
import { ArrowDown, ArrowUp, ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { userStatusColor } from 'shared/lib';

const userStatusTranslate = (status: string) => {
  switch (status) {
    case 'active':
      return 'Активный';
    case 'suspended':
      return 'Заморожен';
    case 'blocked':
      return 'Заблокирован';
    default:
      return status;
  }
};

export const Users = () => {
  const { 0: users, 1: isLoading } = useUnit([
    $getManyUsers,
    getManyUsersFx.pending,
  ]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [statusFilter, setStatusFilter] = useState<string[]>([]); // Изменили на массив строк

  useEffect(() => {
    getManyUsersFx({ statuses: [] });
  }, []);

  const mapStatusUser = {
    blocked: {
      textRu: 'Заблокировать',
      textEn: 'blocked',
      onclick: changeStatusBlockUserFx,
    },
    active: {
      textRu: 'Разблокировать',
      textEn: 'active',
      onclick: changeStatusActivateUserFx,
    },
    suspended: {
      textRu: 'Заморозить',
      textEn: 'suspended',
      onclick: changeStatusSuspendUserFx,
    },
  };
  const statusesUser: ('active' | 'suspended' | 'blocked')[] = [
    'blocked',
    'active',
    'suspended',
  ];

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const matchesSearchQuery = `${user.lastname} ${user.firstname}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesStatus =
        statusFilter.length > 0 ? statusFilter.includes(user.status) : true;
      return matchesSearchQuery && matchesStatus;
    });
  }, [users, searchQuery, statusFilter]);

  const sortedUsers = useMemo(() => {
    return filteredUsers.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();

      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });
  }, [filteredUsers, sortOrder]);

  const rows = sortedUsers.map((user, index) => (
    <Table.Tr bg={index % 2 === 0 ? 'white' : 'brand.1'} key={user.userId}>
      <Table.Td>
        {user.lastname} {user.firstname}
      </Table.Td>
      <Table.Td>{user.email || '-'}</Table.Td>
      <Table.Td>{user.phoneNumber}</Table.Td>
      <Table.Td>
        <Badge color={userStatusColor(user.status)}>
          {userStatusTranslate(user.status)}
        </Badge>
      </Table.Td>
      <Table.Td>{user.userId}</Table.Td>
      <Table.Td>{new Date(user.createdAt).toLocaleDateString()}</Table.Td>
      <Table.Td w={155}>
        <Menu>
          <Menu.Target>
            <Button style={{ float: 'right' }} bg={'brand'} miw={155} w={155}>
              Изменить статус
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            {statusesUser.map(
              (item) =>
                item !== mapStatusUser[user.status].textEn && (
                  <Menu.Item
                    onClick={() =>
                      mapStatusUser[item].onclick({
                        userId: user.userId,
                      })
                    }
                    fw={500}
                    fz={16}
                    c={userStatusColor(item)}
                    key={item}>
                    {mapStatusUser[item].textRu}
                  </Menu.Item>
                ),
            )}
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Stack>
      <Stack>
        <LoadingOverlay h='100vh' visible={isLoading} />
        <Stack>
          <Title order={3}>Все юзеры</Title>
          <Text>Всего пользователей - {users?.length}</Text>
          <Flex gap='md'>
            <TextInput
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              placeholder='Поиск по ФИО'
              mb={20}
            />
            <MultiSelect
              placeholder='Фильтр по статусу'
              value={statusFilter}
              onChange={setStatusFilter}
              data={[
                { value: 'active', label: 'Активный' },
                { value: 'suspended', label: 'Заморожен' },
                { value: 'blocked', label: 'Заблокирован' },
              ]}
              renderOption={(user) => (
                <Text c={userStatusColor(user.option.value)}>
                  {userStatusTranslate(user.option.value)}
                </Text>
              )}
              clearable
              nothingFoundMessage='Ничего не найдено'
            />
          </Flex>
        </Stack>
        <Table>
          <Table.Thead>
            <Table.Tr ta={'center'}>
              <Table.Th>ФИО</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Телефон</Table.Th>
              <Table.Th>Статус</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  sortOrder === 'desc'
                    ? setSortOrder('asc')
                    : setSortOrder('desc')
                }>
                <Flex align={'center'}>
                  {' '}
                  Дата создания{' '}
                  {sortOrder === 'asc' ? (
                    <ArrowDown height={16} />
                  ) : (
                    <ArrowUp height={16} />
                  )}
                </Flex>
              </Table.Th>
              <Table.Th ta={'center'}>Действия</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Stack>
    </Stack>
  );
};
