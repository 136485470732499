import { createStore, sample } from 'effector';
import { editOrgCommissionFx, editStatusManyOrgFx } from './effects';
import { $$getManyOrg } from './attaches';
import { OrgEntityType } from 'entities/entities-types';
import * as ef from './effects';
import { appReset } from 'entities/events';

export const $getManyOrg = createStore<OrgEntityType[]>([])
  .on(ef.editStatusManyOrgFx.doneData, (state, payload) => {
    return payload.data;
  })
  .reset(appReset);

sample({
  clock: editStatusManyOrgFx.done,
  source: $$getManyOrg.$store.map((s) => s.res?.data),
  fn: (src, clk) => {
    return {
      currentPage: 1,
      itemsPerPage: 1000,
    };
  },
  target: $$getManyOrg.attachedFx,
});

sample({
  clock: editOrgCommissionFx.done,
  target: $$getManyOrg.attachedFx,
});
