import {
  Flex,
  NumberFormatter,
  Progress,
  Stack,
  Text,
  Tooltip,
  Card,
  Box,
} from '@mantine/core';
import { ResponsivePie } from '@nivo/pie';

import { PaymentMetrics } from 'entities/statistic/type';
import { or } from 'ramda';

export const CardMetrics = ({
  item,
  title,
}: {
  item: PaymentMetrics | undefined;
  title: string;
}) => {
  return (
    <Stack
      w={'100%'}
      bg={'white'}
      gap={0}
      p={16}
      className='shadow'
      style={{ borderRadius: '10px', border: '1px solid #e0e0e0' }}>
      <Text fw={400} fz={20} mb={4}>
        {title}
      </Text>
      <Progress.Root radius='md' size={50}>
        <Tooltip label={`${item?.percentSuccessfulPayments}%`} withArrow>
          <Progress.Section
            value={or(item?.percentSuccessfulPayments, 0)}
            color='#4dabf7'>
            <Progress.Label fw={500} fz='xs'>
              <Stack gap={0} align='center'>
                <Text fz={14} lh={1}>
                  Успешные
                </Text>
                <Text fz={14} fw={500}>
                  {item?.successfulPayments}
                </Text>
              </Stack>
            </Progress.Label>
          </Progress.Section>
        </Tooltip>
        <Tooltip label={`${item?.percentUnsuccessfulPayments}%`} withArrow>
          <Progress.Section
            value={or(item?.percentUnsuccessfulPayments, 0)}
            color='#ff6b6b'>
            <Progress.Label fw={500} fz='xs'>
              <Stack gap={0} align='center'>
                <Text fz={14} lh={1}>
                  Неудачные
                </Text>
                <Text fz={14} fw={500}>
                  {item?.unsuccessfulPayments}
                </Text>
              </Stack>
            </Progress.Label>
          </Progress.Section>
        </Tooltip>
      </Progress.Root>

      <Stack gap={0} mt={16}>
        <Flex gap={3} justify='space-between'>
          <Text>Всего:</Text>
          <Text fw={500}>{item?.numberOfPayments} шт</Text>
        </Flex>
        <Flex gap={3} justify='space-between'>
          <Text>Сумма:</Text>
          <Text fw={500}>
            <NumberFormatter
              thousandSeparator=' '
              decimalSeparator=','
              suffix=' ₽'
              value={item?.paymentAmount}
            />
          </Text>
        </Flex>
      </Stack>
      <Stack mt={8}>
        <Flex gap={3} c={'brand'} align={'center'} justify='space-between'>
          <Text fw={500}>Наш доход:</Text>
          <Text fw={700} fz={20}>
            <NumberFormatter
              thousandSeparator=' '
              decimalSeparator=','
              suffix=' ₽'
              value={item?.revenue}
            />
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
  // };
};
