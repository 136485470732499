import { MantineProvider } from '@mantine/core';
import React, { lazy } from 'react';
import AppRouter from './AppRouter';
import { Notifications } from '@mantine/notifications';
import { theme } from 'app/theme/theme';

const RootProvider = () => {
  return (
    <div className='app'>
      <MantineProvider defaultColorScheme='light' theme={theme}>
        <Notifications />
        <AppRouter />
      </MantineProvider>
    </div>
  );
};

export default RootProvider;
